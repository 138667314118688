import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivationStart, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { MenuController, NavParams } from '@ionic/angular';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { NavParamsService } from 'src/app/services/nav-params.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { changePage } from 'src/global';
import { TestingService } from 'src/app/services/testing.service';

@Component({
  selector: 'nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

  userComplete:UserComplete;
  initialLoginCheck = false;
  showFiller = false;
  displaySideMenu = false;

  @ViewChild(RouterOutlet) outlet: RouterOutlet;


  constructor(private router:Router, private userAuthService:UserAuthService,private menu: MenuController,
    private navParamsService:NavParamsService, private storageService:StorageService, private testingService:TestingService) { 
  }

  crash(){
    this.testingService.crash();
  }

  async ngOnInit() {

    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === "administration")
        this.outlet.deactivate();
    });

    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      //False means the observable has just loaded
      if (userComplete != false){
        this.userComplete = userComplete;
        this.initialLoginCheck = true;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      this.initialLoginCheck = true;
      this.userComplete = this.userAuthService.userComplete;
    }
  }

  openMenu(){
    this.menu.enable(true, 'navMenu');
    this.menu.open("navMenu");
  }

  setMenu(display){
    console.log(display)
    this.displaySideMenu = display;
  }

  async changePage(url){
    this.menu.close("navMenu");
    this.displaySideMenu = false;

    changePage(url,this.router);
  }

  changeLoginStatus(){
    if (this.userComplete){
      this.userAuthService.loggedOut();
    }
    else{
      this.changePage("/login");
    }
  }

 
  loginWithEmailPass(){
    this.userAuthService.loginWithEmailPass("colin@brilliantlabs.ca","Gh23kl89");
  }


  logout(){
    this.menu.close("navMenu");
    this.userAuthService.loggedOut();
  }
}
