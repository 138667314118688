import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { changePage, connLink, presentLoading, presentToast } from 'src/global';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {

  token = "";
  email = "";
  validResetToken = false;
  loaded = false;

  password = "";
  confirmPassword = "";

  attemptedPasswordReset = false;
  passwordWasReset = false;

  constructor(private route: ActivatedRoute, private http:HttpClient, private toastController:ToastController,
    private loadingController:LoadingController, private router:Router) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }

        this.email = params["email"];
        this.token = params["token"];

        if (this.email && this.token){
          this.http.post(connLink + "/validatePasswordChangeRequestToken",{email:this.email,token:this.token}).subscribe(
        
          (response) => {   
            this.loaded = true;
            this.validResetToken = true;
            console.log(response);  
          },
          (errorObj) => {  
            this.loaded = true;
            this.validResetToken = false;
            presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
        })
        }
        else{

        }
        this.loaded = true;
      }
    );
  }

  resetPassword(){

    let errorMessage = "";
    if (this.password == "" || this.confirmPassword == ""){
      errorMessage = "You must enter a password";
    }
    else if (this.password != this.confirmPassword){
      errorMessage = "Both passwords must match";
    }
    else if (this.password.length < 8 || this.password.length > 20){
      errorMessage = "Password must be between 8 and 20 characters";
    }

    if (errorMessage){
      presentToast(errorMessage,"danger",this.toastController);
    }
    else{
      presentLoading(this.loadingController).then(()=>{
        this.http.post(connLink + "/changePassword",{email:this.email,token:this.token,password:this.password}).subscribe(
        
          (response) => {   
            this.attemptedPasswordReset = true;
            this.passwordWasReset = true;
            this.loadingController.dismiss();
              
          },
          (errorObj) => {  
            this.loadingController.dismiss();
            this.passwordWasReset = true;
            console.log(errorObj);
            presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);

        })
      })
    }
  }

  changePage(url){
    changePage(url,this.router);
  }

}
