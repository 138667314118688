
export enum PushNotificationName {
    ActionChanged = "1",
    ActionOn = "2",
    ActionOff = "3",
    BadStatValue = "4"
  }

export interface UserPushNotification{
    //Database table
    id?:number,
    userId: number
    pushNotificationId?: number,
}

