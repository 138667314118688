import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../shared.module';
import { ComponentsModule } from './components/components.module';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { ImageProvider } from './providers/image/image';

@NgModule({
  declarations: [AppComponent],
  imports: [
    IonicStorageModule.forRoot() ,ComponentsModule,BrowserAnimationsModule,HttpClientModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule, SharedModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },ImageProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
