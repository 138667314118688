import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { MaterialModule } from './material.module';

@NgModule({
  imports: [MaterialModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule],
  exports: [MaterialModule,OwlDateTimeModule,OwlNativeDateTimeModule],

})
export class SharedModule {}
