import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PlantComplete } from 'node_js/models/plant-complete';
import { PlantTemplate } from 'node_js/models/tables/plant-template';
import { PlantTemplateComplete } from 'node_js/models/plant-template-complete';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class PlantTemplateService {

  constructor(private http:HttpClient, private toastController:ToastController) { 
    
  }

  createOrUpdatePlantTemplate(plantComplete:PlantComplete,plantTemplate:PlantTemplate,user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/createOrUpdatePlantTemplate",{plantComplete,plantTemplate,user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve(response["data"]["plantTemplateComplete"]);                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }

  //Get personal templates
  getPersonalPlantTemplatesByUserId(user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/getPlantTemplatesByUserId",{user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve(response["data"]["plantTemplatesComplete"]);                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }

  //Get global and personal templates
  getAllPlantTemplatesByUserId(user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/getAllPlantTemplatesByUserId",{user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve(response["data"]["templates"]);                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
