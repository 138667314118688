import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GreenhouseUserRequest } from 'node_js/models/tables/greenhouse-user-request';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class GreenhouseUserRequestService {

  constructor(private http:HttpClient,private toastController:ToastController) { }

  acceptUsers(greenhouseId:number,userIds:number[],user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/acceptGreenhouseUserRequests",{greenhouseId,userIds,user}).subscribe(
        (response) => {      
          console.log(response);  
          resolve(response["message"]);                 
        },
        (errorObj) => {                  
          reject(errorObj.error);
      })
    });
  }

  rejectUsers(greenhouseId:number,userIds:number[],user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/rejectGreenhouseUserRequests",{greenhouseId,userIds,user}).subscribe(
        (response) => {      
          console.log(response);  
          resolve(response["message"]);                 
        },
        (errorObj) => {                  
          reject(errorObj.error);
      })
    });
  }
}
