import { Component, OnInit } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { UserComplete } from 'node_js/models/user-complete.model';
import { PushNotificationName, UserPushNotification } from 'node_js/models/tables/user-push-notification';
import { User } from '../../../../node_js/models/tables/user.model';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { presentLoading, presentToast } from 'src/global';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {

  PushNotificationName = PushNotificationName;

  userComplete:UserComplete;

  selectedPushNotificationsIds:number[] = [];

  constructor(private userAuthService:UserAuthService, private pushNotificationService:PushNotificationService,
    private loadingController:LoadingController,private toastController:ToastController) { }

  ngOnInit() {
    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
        this.setup();
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
        this.setup();
      }
    }
  }

 
  setup(){
    this.selectedPushNotificationsIds = []
    for (let i = 0; i < this.userComplete.userPushNotifications.length; i++){
      let userPushNotification:UserPushNotification = this.userComplete.userPushNotifications[i];
      this.selectedPushNotificationsIds.push(userPushNotification.pushNotificationId);
    }
    console.log("selected",this.selectedPushNotificationsIds);
  }

  isChecked(pushId){
    for (let i = 0; i < this.selectedPushNotificationsIds.length; i++){
      if (this.selectedPushNotificationsIds[i] == pushId){
        return true;
      }
    }
    return false;
  }

  togglePush(pushId,checked){
    let foundPushId = false;
    for (let i = 0; i < this.selectedPushNotificationsIds.length; i++){
      if (this.selectedPushNotificationsIds[i] == pushId && !checked){
        this.selectedPushNotificationsIds.splice(i,1);
        foundPushId = true;
        break;
      }
    }

    if (!foundPushId){
      this.selectedPushNotificationsIds.push(pushId);
    }

    console.log(this.selectedPushNotificationsIds);
  }

  updatePushNotificationSettings(){
    presentLoading(this.loadingController).then(()=>{

      let selectedPushNotificationsIds = [...this.selectedPushNotificationsIds];

      console.log("START");
      console.log("current push from db",this.userComplete.userPushNotifications);
      console.log("selected",selectedPushNotificationsIds);
      //Find out which notifications we're deleting
      for (let i = 0; i < this.userComplete.userPushNotifications.length; i++){
        let userPushNotification:UserPushNotification = this.userComplete.userPushNotifications[i];
        let deleteNotification = true;
        for (let j = 0; j < selectedPushNotificationsIds.length; j++){
          if (userPushNotification.pushNotificationId == selectedPushNotificationsIds[j]){
            console.log("splicing");
            selectedPushNotificationsIds.splice(j,1);
            deleteNotification = false;
            break;
          }
        }
        if (deleteNotification){
          this.userComplete.userPushNotifications.splice(i,1);
          i--;
        }
      }

      console.log("newly selected ids",selectedPushNotificationsIds);

      //Loop through new push notifications
      for (let i = 0; i < selectedPushNotificationsIds.length; i++){
        let userPushNotification:UserPushNotification = {
          userId:this.userComplete.user.id,
          pushNotificationId:selectedPushNotificationsIds[i]
        };
        this.userComplete.userPushNotifications.push(userPushNotification);
      }
      console.log("sending",this.userComplete.userPushNotifications);
      this.pushNotificationService.updateUserPushNotificationSettings(this.userComplete.user,this.userComplete.userPushNotifications).then(()=>{
        presentToast("Push notification settings updated","success",this.toastController);
      }).catch(err=>{
        presentToast("Error updating settings, try again later.","danger",this.toastController);
      }).finally(()=>{
        this.userComplete.userPushNotifications = this.userAuthService.userComplete.userPushNotifications;
        console.log("reloaded notifications",this.userComplete.userPushNotifications);
        this.loadingController.dismiss();
      })
    });
    
  }

  triggerPush(){
    this.pushNotificationService.refreshPushNotificationToken(this.userComplete.user,this.userAuthService.userDevice);
  }

}
