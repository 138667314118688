import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, NavParams } from '@ionic/angular';


import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/material.module';
import { NavComponent } from './nav/nav.component';
import { Storage } from '@ionic/storage';
import { AddGreenhouseComponent } from './add-greenhouse/add-greenhouse.component';
import { FormsModule } from '@angular/forms';
import { GreenhouseUsersComponent } from './greenhouse-users/greenhouse-users.component';
import { AddPlantComponent } from './add-plant/add-plant.component';
import { UpsertPlantComponent } from './upsert-plant/upsert-plant.component';
import { SharedModule } from 'src/shared.module';



@NgModule({
  declarations: [NavComponent,AddGreenhouseComponent, GreenhouseUsersComponent,AddPlantComponent,UpsertPlantComponent],
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    MaterialModule,
    FormsModule,
    RouterModule,
  ],
  exports: [NavComponent,AddGreenhouseComponent, GreenhouseUsersComponent,AddPlantComponent,UpsertPlantComponent],
  providers: [NavParams,Storage]


})
export class ComponentsModule { }
