import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { User } from 'node_js/models/tables/user.model';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { UserService } from 'src/app/services/user.service';
import { changePage, presentLoading, presentToast } from 'src/global';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit {

  user:User;
  confirmPassword = "";


  constructor(private userAuthService:UserAuthService, private toastController:ToastController, private userService:UserService,
    private router:Router, private loadingController:LoadingController) { }

  ngOnInit() {
    this.user = this.userService.createNewUser();
  }
  
  async signUp(){
   
    presentLoading(this.loadingController,"Signing Up...").then(()=>{
      let errorMessage = this.userService.validateUserBeforeSignUp(this.user,this.confirmPassword);

    
      if (errorMessage != ""){
        presentToast(errorMessage,"danger",this.toastController);
      }
      else{
        this.userAuthService.createUser(this.user).finally(()=>{
          this.loadingController.dismiss();
        });
      }
    })
    
  }

  changePage(url){
    changePage(url,this.router);
  }

  

}


