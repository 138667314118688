import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { GreenhouseComplete } from 'node_js/models/greenhouse-complete';
import { PlantComplete } from 'node_js/models/plant-complete';
import { GreenhouseCompleteService } from 'src/app/services/greenhouse-complete.service';
import { changePage, presentToast } from 'src/global';

@Component({
  selector: 'app-greenhouse-plant',
  templateUrl: './greenhouse-plant.page.html',
  styleUrls: ['./greenhouse-plant.page.scss'],
})
export class GreenhousePlantPage implements OnInit {

  selectedGreenhouseComplete:GreenhouseComplete = null;
  greenhousesComplete:GreenhouseComplete[] = null;


  selectedPlantComplete:PlantComplete = null;

  constructor(private route: ActivatedRoute, private router:Router, private greenhouseCompleteService:GreenhouseCompleteService,
    private toastController:ToastController) { }

  async ngOnInit() {

    //Subscribe to greenhouse changes
    this.greenhouseCompleteService.greenhouseObservable.subscribe((greenhousesComplete:GreenhouseComplete[])=>{
      //Null means the observable has just loaded
      if (greenhousesComplete != null){
        this.greenhousesComplete = greenhousesComplete;
        this.setup();
      }
      else{
        this.greenhousesComplete = [];
        presentToast("There weren't any greenhouses loaded","danger",this.toastController);
        changePage("./greenhouse",this.router);
      }
      console.log("greenhouse obs triggered",this.greenhousesComplete);

    })
    
  }

  setup(){
    let greenhouseId = this.route.snapshot.paramMap.get('greenhouseId');
    if (greenhouseId){
      for (let i = 0; i < this.greenhousesComplete.length; i++){
        if (this.greenhousesComplete[i].greenhouse.id == parseInt(greenhouseId)){
          this.selectedGreenhouseComplete = this.greenhousesComplete[i];
          break;
        }
      }

      if (this.selectedGreenhouseComplete){
        let plantId = this.route.snapshot.paramMap.get('plantId');
        if (plantId){
          let plantsComplete:PlantComplete[] = this.selectedGreenhouseComplete.plantsComplete;
          for (let i = 0; i < plantsComplete.length; i++){
            if (plantsComplete[i].plant.id == parseInt(plantId)){
              this.selectedPlantComplete = plantsComplete[i];
              break;
            }
          }
        }
      }
    }
    console.log("selected greenhouse",this.selectedGreenhouseComplete);
    console.log("selected plant",this.selectedPlantComplete);
  }

  changePage(url){
    changePage(url,this.router);
  }

}
