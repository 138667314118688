import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgControl } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PersonalPlantTemplatesPageRoutingModule } from './personal-plant-templates-routing.module';

import { PersonalPlantTemplatesPage } from './personal-plant-templates.page';
import { SharedModule } from 'src/shared.module';
import { ComponentsModule } from 'src/app/components/components.module';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
  imports: [
    
    CommonModule,
    FormsModule,
    IonicModule,
    FormsModule,
    SharedModule,
    ComponentsModule,
    PersonalPlantTemplatesPageRoutingModule,
  ],
  declarations: [PersonalPlantTemplatesPage],
  providers: [FormsModule]
})
export class PersonalPlantTemplatesPageModule {}
