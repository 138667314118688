import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Greenhouse } from 'node_js/models/tables/greenhouse';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class GreenhouseService {

  constructor(private http:HttpClient, private toastController:ToastController) { }

  

  createGreenhouse(greenhouse:Greenhouse,user:User){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/createGreenhouse",{greenhouse,user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }

  updateGreenhouse(greenhouse:Greenhouse,user:User, newPassword){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/updateGreenhouse",{greenhouse,user,newPassword}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
