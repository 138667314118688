import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class GreenhouseUserService {

  constructor(private http:HttpClient,private toastController:ToastController) { }

  joinGreenhouse(greenhouseId,greenhousePassword,user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/joinGreenhouse",{greenhouseId,greenhousePassword,user}).subscribe(
        (response) => {      
          console.log(response);  
          resolve(response["message"]);                 
        },
        (errorObj) => {                  
          reject(errorObj.error);
      })
    });
  }
}
