import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { changePage, connLink, presentToast } from 'src/global';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { FailedResponse, ErrorCode } from '../../../node_js/models/failed-response.model';
import { SuccessResponse } from 'node_js/models/success-response';
import { User } from 'node_js/models/tables/user.model';
import { Device } from '@capacitor/device';
import { UserDevice } from 'node_js/models/tables/user-device.model';
import { UserComplete } from 'node_js/models/user-complete.model';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  userComplete:UserComplete;
  userDevice:UserDevice;
  initalLoginCheck = false;
  isLoggedIn = false;

  public userObservable: BehaviorSubject<any>;


  constructor(private http: HttpClient, private toastController:ToastController, private storageService:StorageService,
    private router:Router) { 
    this.userObservable = new BehaviorSubject(false);
    this.initalLogin();
    
  }

  async initalLogin(){
    await SplashScreen.show({
      autoHide: false,
    });
    new Promise<void>(async (resolve,reject)=>{
      let loginToken = await this.getLoginToken();
      if (loginToken && loginToken != ""){
        console.log("found login token:" + loginToken);
        this.loginWithToken(loginToken).finally(()=>{
          console.log("finished login with token");
          resolve();
        })
      }
      else{
        console.log("There wasn't a login token")
        resolve();
      }
    }).finally(()=>{
      this.initalLoginCheck = true;
      console.log("Finished login check: " + this.isLoggedIn);
      if (!this.isLoggedIn){
        this.loggedOut();
      }
    })
   
  }

  triggerUserObservable(){
    this.userObservable.next(this.userComplete);
  }

  createUser(user:User){

    let password = user.password;
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/createUser",{user}).subscribe(
        (response) => {      
          console.log(response,user.email,password);
          this.loginWithEmailPass(user.email,password).then(()=>{
            resolve();
          })
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
          console.log('error caught in component',errorObj.error);
      });
    })
  
  }

  loginWithToken(loginToken){
    console.log("attempting login with token:" + loginToken);
    return new Promise<void>((resolve,reject)=>{
      Device.getId().then(device=>{
        this.http.post(connLink + "/loginWithToken",{loginToken,deviceId:device.uuid}).subscribe(
          async (response:any) => {  
            this.userDevice = response.data.userDevice;
            await this.loggedIn(response.data.userComplete,false,response.data.loginToken);
            resolve();
          },
          async (errorObj) => {                  
            //presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
            console.log('error caught in component',errorObj.error);
            await this.loggedOut();
            reject(errorObj);
        });
      });
      
    })
   
    
  }

  async loginWithEmailPass(email,password){

    return new Promise<UserComplete>((resolve,reject)=>{
      Device.getId().then(device=>{
        this.http.post(connLink + "/loginWithEmailPass",{email:email,password:password,deviceId:device.uuid}).subscribe(
          async (response:SuccessResponse) => {      
            console.log("logging in",response);
            this.userDevice = response.data.userDevice;
            presentToast("Logged in","success",this.toastController,1000);
            await this.loggedIn(response.data.userComplete,false,response.data.loginToken);
            resolve(this.userComplete);
          },
          (errorObj:FailedResponse) => {  
            console.log(errorObj);                
            if (errorObj.error.errorCode == ErrorCode.DataNotFound){
              presentToast("A user under the email " + email + " hasn't been created","danger",this.toastController);
            }
            else if (errorObj.error.errorCode == ErrorCode.InvalidPassword){
              presentToast("The password is incorrect","danger",this.toastController);
            }
            else{
              presentToast("There was an unexpected error on our end, please try again later.","danger",this.toastController);
            }
            console.log('error caught in component',errorObj);
            reject(errorObj);
        });
      })
      
    })
 
  }

  async loggedIn(userComplete:UserComplete,goToHome:boolean,loginToken?){
    this.userComplete = userComplete;
    console.log("found device:",this.userDevice);

    if (!userComplete){
      await this.loggedOut();
      return;
    }
    this.isLoggedIn = true;
    console.log("LOGGED");
    if (this.router.url == "/login"|| this.router.url == "/forgot-password"|| this.router.url == "/sign-up"|| 
          this.router.url == "/initial-startup"){
            goToHome = true;
          }
    if (goToHome){
      changePage("/greenhouse",this.router);
    }
    console.log("going to home:",goToHome);
    if (loginToken){
      await this.setLoginToken(loginToken);
    }
    this.triggerUserObservable();
    
  }

  async loggedOut(){
    console.log("logged out");
    changePage("/login",this.router);
    return new Promise<void>(async (resolve,reject)=>{
      let loginToken = await this.getLoginToken();
      console.log("login token:",loginToken);

      new Promise<void>((resolve,reject)=>{
        console.log (connLink + "/deleteLoginToken");
        this.http.post(connLink + "/deleteLoginToken",{loginToken:loginToken}).subscribe(
          (response:any) => {      
            resolve();
          },
          (errorObj) => {                  
            
            console.log('error caught in component',errorObj.error);
            resolve();
        });
      }).finally(async ()=>{
        console.log("logged out, finally");
        this.userComplete = null;
        this.isLoggedIn = false;
        this.triggerUserObservable();

        let skipStartup = await this.storageService.getStorage("skipStartup");
        console.log("skip startup:",skipStartup);
      
        resolve();
      })
    
    })
    
  }

  async getLoginToken(){
    return await this.storageService.getStorage("loginToken");
  }

  async setLoginToken(loginToken:string){
    return await this.storageService.setStorage("loginToken",loginToken);
  }
}
