
export enum ErrorCode{
    InvalidPassword = "invalid_password",
    MissingUser = "missing_user",
    InvalidQuery = "invalid_query",
    QueryFailed = "query_failed",
    MissingBodyData = "missing_body_data",
    InvalidBodyData = "invalid_body_data",
    InvalidApiToken = "invalid_api_token",
    UnexpectedError = "unexpected_error",
    PreventingDuplicateData = "preventing_duplicate_data",
    DataNotFound = "data_not_found",
    SessionExpired = "session_expired",
    NoAccount = "no_account",
    MissingPermission = "missing_permission"
}

export interface FailedResponse{
    error: {
        errorMessage: string
        errorCode:ErrorCode
    }
    
}
