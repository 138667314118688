import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { Greenhouse } from 'node_js/models/tables/greenhouse';
import { GreenhousePlant } from 'node_js/models/tables/greenhouse-plant';
import { Plant } from 'node_js/models/tables/plant';
import { PlantComplete } from 'node_js/models/plant-complete';
import { PlantTemplateComplete } from 'node_js/models/plant-template-complete';
import { TemplateCombo } from 'node_js/models/template-combo.model';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { GreenhousePlantService } from 'src/app/services/greenhouse-plant-service';
import { PlantTemplateService } from 'src/app/services/plant-template.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { changePage, presentToast } from 'src/global';

enum PlantTemplateType {
  Global = "Global",
  Personal = "Personal"
}

@Component({
  selector: 'app-add-plant',
  templateUrl: './add-plant.component.html',
  styleUrls: ['./add-plant.component.scss'],
})
export class AddPlantComponent implements OnInit {
  PlantTemplateType = PlantTemplateType;
  selectedPlantTemplate:PlantTemplateType = PlantTemplateType.Global; 

  personalPlantTemplatesComplete:PlantTemplateComplete[] = [];
  globalPlantTemplatesComplete:PlantTemplateComplete[] = [];


  //This should be sent by our greenhouse page
  greenhouseId = null;
  userComplete:UserComplete;
  /*
  globalPlants:Plant[] = [
    {
      id:0,
      name: "Corn",
      difficulty: "Beginner",
      photoUrl: "/assets/images/flower.png"
    },
    {
      id:1,
      name: "Tomato",
      difficulty: "Beginner",
      photoUrl: "/assets/images/tomato.jpeg"
    }
  ]

  personalPlants:Plant[] = [
    {
      id:0,
      name: "Corn",
      difficulty: "Beginner",
      photoUrl: "/assets/images/flower.png"
    },
    {
      id:1,
      name: "Tomato",
      difficulty: "Beginner",
      photoUrl: "/assets/images/tomato.jpeg"
    }
  ]
  */

  constructor(private modalController:ModalController, private router:Router, private userAuthService:UserAuthService,
    private plantTemplateService:PlantTemplateService, private greenhousePlantService:GreenhousePlantService, private toastController:ToastController) { }

  ngOnInit() {
    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
        this.setup();
      }
      else{
        this.userComplete = null;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
        this.setup();
      }
    }
  }

  setup(){
    this.plantTemplateService.getAllPlantTemplatesByUserId(this.userComplete.user).then((templates:TemplateCombo)=>{
      this.personalPlantTemplatesComplete = templates.personalTemplates;
      this.globalPlantTemplatesComplete = templates.globalTemplates;
      console.log("got plants",templates);
    })
  }

  cancel(){
    this.modalController.dismiss();
  }

  addPlants(){
    this.modalController.dismiss();
  }

  changePage(url){
    this.modalController.dismiss();
    changePage(url,this.router);
  }

  selectedPlant(plantTemplateComplete:PlantTemplateComplete){
    if (this.greenhouseId != null){
      this.greenhousePlantService.createOrUpdateGreenhousePlant(plantTemplateComplete.plantComplete,this.greenhouseId,plantTemplateComplete.plantTemplate.id,this.userComplete.user).then(()=>{
        this.modalController.dismiss();
      }).catch(err=>{
        presentToast(err,'danger',this.toastController);
      })
    }
  }
}
