import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Plant } from 'node_js/models/tables/plant';
import { PlantComplete } from 'node_js/models/plant-complete';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class GreenhousePlantService {

  constructor(private http:HttpClient, private toastController:ToastController) {

  }

  createOrUpdateGreenhousePlant(plantComplete:PlantComplete,greenhouseId,plantTemplateId,user:User){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/createOrUpdateGreenhousePlant",{plantComplete,plantTemplateId,greenhouseId,user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve(response);                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }

}
