import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'greenhouse',
    loadChildren: () => import('./pages/greenhouse/greenhouse.module').then( m => m.GreenhousePageModule)
  },
  {
    path: 'greenhouse-plant',
    loadChildren: () => import('./pages/greenhouse-plant/greenhouse-plant.module').then( m => m.GreenhousePlantPageModule)
  },
  { 
    path: 'greenhouse-plant/:greenhouseId',
    loadChildren: () => import('./pages/greenhouse-plant/greenhouse-plant.module').then( m => m.GreenhousePlantPageModule)
  },
  { 
    path: 'greenhouse-plant/:greenhouseId/:plantId',
    loadChildren: () => import('./pages/greenhouse-plant/greenhouse-plant.module').then( m => m.GreenhousePlantPageModule)
  },
  {
    path: 'plant-template',
    loadChildren: () => import('./pages/plant-template/plant-template.module').then( m => m.PlantTemplatePageModule)
  },
  {
    path: 'personal-plant-templates',
    loadChildren: () => import('./pages/personal-plant-templates/personal-plant-templates.module').then( m => m.PersonalPlantTemplatesPageModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./pages/logs/logs.module').then( m => m.LogsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
