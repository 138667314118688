import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GreenhouseComplete } from 'node_js/models/greenhouse-complete';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { BehaviorSubject } from 'rxjs';
import { connLink, presentToast } from 'src/global';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root',
})
export class TestingService {


  constructor(private http:HttpClient, private toastController:ToastController, private userAuthService:UserAuthService) {

  

  }

  crash(){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/crash",{}).subscribe(
  
        (response) => {      
          console.log("response",response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
