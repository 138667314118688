import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { GreenhouseComplete } from 'node_js/models/greenhouse-complete';
import { AddGreenhouseComponent } from 'src/app/components/add-greenhouse/add-greenhouse.component';
import { AddPlantComponent } from 'src/app/components/add-plant/add-plant.component';
import { UpsertPlantComponent, PlantType } from 'src/app/components/upsert-plant/upsert-plant.component';
import { GreenhouseUsersComponent } from 'src/app/components/greenhouse-users/greenhouse-users.component';
import { GreenhouseCompleteService } from 'src/app/services/greenhouse-complete.service';
import { User } from 'node_js/models/tables/user.model';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { UserComplete } from 'node_js/models/user-complete.model';
import { presentLoading } from 'src/global';
import { Greenhouse } from 'node_js/models/tables/greenhouse';
import { GreenhouseUser } from 'node_js/models/tables/greenhouse-user';
import { PermissionType } from 'node_js/models/tables/permission';

@Component({
  selector: 'app-greenhouse',
  templateUrl: './greenhouse.page.html',
  styleUrls: ['./greenhouse.page.scss'],
})
export class GreenhousePage implements OnInit {

  PermissionType=PermissionType;

  selectedGreenhouseComplete:GreenhouseComplete = null;
  selectedGreenhouseUser:GreenhouseUser = null
  greenhousesComplete:GreenhouseComplete[] = null;

  userComplete:UserComplete;

  constructor(private modalController:ModalController, private greenhouseCompleteService:GreenhouseCompleteService,
    private userAuthService:UserAuthService, private loadingController:LoadingController) { }

  ngOnInit() {

    var date = new Date();
    let utc = date.toUTCString();
   
    ///this.selectedGreenhouseComplete.plantsComplete[0].plant.
    this.greenhousesComplete = this.greenhouseCompleteService.greenhousesComplete;

    //Subscribe to greenhouse changes
    this.greenhouseCompleteService.greenhouseObservable.subscribe((greenhousesComplete:GreenhouseComplete[])=>{
      //False means the observable has just loaded
      if (greenhousesComplete != null){
        this.greenhousesComplete = greenhousesComplete;
      }
      else{
        this.greenhousesComplete = [];
      }
      console.log("greenhouse obs triggered",this.greenhousesComplete);

    })

    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
      }
    }
  }

  changedSelectedGreenhouse(){
    console.log("changed selected greenhouse");
    for (let i = 0; i < this.selectedGreenhouseComplete.greenhouseUsersComplete.length; i++){
      console.log("comparing ",this.selectedGreenhouseComplete.greenhouseUsersComplete[i]);
      if (this.selectedGreenhouseComplete.greenhouseUsersComplete[i].greenhouseUser.userId == this.userComplete.user.id){
        this.selectedGreenhouseUser = this.selectedGreenhouseComplete.greenhouseUsersComplete[i].greenhouseUser;
      }
    }
    console.log("selected greenhouseuser:",this.selectedGreenhouseUser);
  }

  async openEditUsersModel() {
    console.log("greenhouse users",this.selectedGreenhouseComplete);

    const modal = await this.modalController.create({
      component: GreenhouseUsersComponent,
      cssClass: 'custom-modal-css',
      componentProps: {greenhouseComplete:this.selectedGreenhouseComplete,user:this.userComplete.user,greenhouseUser:this.selectedGreenhouseUser}
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

  }

  async openAddPlantModal() {
    const modal = await this.modalController.create({
      component: AddPlantComponent,
      cssClass: 'custom-modal-css',
      componentProps: {greenhouseId:this.selectedGreenhouseComplete.greenhouse.id}
      
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.reloadGreenhouses();
    
  }

  reloadGreenhouses(){
    console.log("reloading greenhouses");
    presentLoading(this.loadingController).then(()=>{
      this.greenhouseCompleteService.getGreenhousesComplete().finally(()=>{
        if (this.selectedGreenhouseComplete){
          let foundSelectedGreenhouse = false;
          for (let i = 0; i < this.greenhousesComplete.length; i++){
            let greenhouse:Greenhouse = this.greenhousesComplete[i].greenhouse;
            if (greenhouse.id == this.selectedGreenhouseComplete.greenhouse.id){
              this.selectedGreenhouseComplete = this.greenhousesComplete[i];
              foundSelectedGreenhouse = true;
              break;
            }
          }
          if (!foundSelectedGreenhouse){
            this.selectedGreenhouseComplete = null;
          }
        }
        
        this.loadingController.dismiss();
      })
    })
  }
  
  async openNewGreenhouseModel(editing) {

    let componentProps = {editing};
    if (this.selectedGreenhouseComplete && editing){
      componentProps["greenhouse"] = this.selectedGreenhouseComplete.greenhouse;
    }
    

    const modal = await this.modalController.create({
      component: AddGreenhouseComponent,
      cssClass: 'custom-modal-css',
      componentProps:componentProps
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.reloadGreenhouses();
   
  }

  async selectedPlant(plantComplete){

    const modal = await this.modalController.create({
      component: UpsertPlantComponent,
      cssClass: 'custom-modal-css',
      componentProps: {plantComplete,currentPlantType:PlantType.Greenhouse,greenhouseId:this.selectedGreenhouseComplete.greenhouse.id}
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      console.log("confirm?");
    }
    else if (role === "cancel"){
      console.log("cancel");
    }
    //changePage(`/greenhouse-plant/${this.selectedGreenhouseComplete.greenhouse.id}/${id}`,this.router);
  }

}
