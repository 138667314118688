import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GreenhouseComplete } from 'node_js/models/greenhouse-complete';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { BehaviorSubject } from 'rxjs';
import { connLink, presentToast } from 'src/global';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root',
})
export class GreenhouseCompleteService {

  greenhousesComplete:GreenhouseComplete[] = [];
  userComplete:UserComplete;

  public greenhouseObservable: BehaviorSubject<any>;


  constructor(private http:HttpClient, private toastController:ToastController, private userAuthService:UserAuthService) {
    this.greenhouseObservable = new BehaviorSubject(false);

    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
        this.getGreenhousesComplete();
      }
      else if (userComplete != false){
        this.greenhouseObservable.next(null);
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
        this.getGreenhousesComplete();

      }
    }
  }

  getGreenhousesComplete(){
    return new Promise<void>((resolve,reject)=>{
      console.log("USER",this.userComplete);
      this.http.post(connLink + "/getGreenhousesCompleteByUserId",{user:this.userComplete.user}).subscribe(
  
        (response) => {      
          console.log("response",response);  
          this.greenhouseObservable.next(response["data"]["greenhousesComplete"]);
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
