
export enum PermissionType {
    User = 1,
    Admin = 2,
    Owner = 3,
    Editor = 4,
    Viewer = 5
}

export interface Permission{
    id?:number,
    name: string
}