
export enum FrequencyType {
    Hours = "hours",
    Days = "days",
    Weeks = "weeks",
    Months = "months"
}

export interface PlantAction
{
    id?:number,
    name: string,
    frequencyType: FrequencyType,
    frequencyAmount: number,
    plantStatId:number,
    plantId: number,
    nextActionStartDate: string,
    nextActionEndDate: string,
    durationMinutes: number,
    runAutomatically: boolean,
    active: boolean
}


