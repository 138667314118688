import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { LoadingController, ToastController } from '@ionic/angular';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { changePage, presentLoading, presentToast } from 'src/global';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  header = "Login";
  email = "";
  password = "";
  constructor(private router:Router, private userAuthService:UserAuthService, private toastController:ToastController, 
    private loadingController:LoadingController, private pushNotificationService:PushNotificationService) { }

  async ngOnInit() {
    //this.testFCMSetup();
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          alert('Push received: ' + JSON.stringify(notification));
        }
      );
  }

  testFCMSetup(){
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
          PushNotifications.addListener('registration',(token: Token) => {
            alert(token.value);
            this.email = token.value;
          });

          PushNotifications.addListener('registrationError',(error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
          });

          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register().then();

        
      } else {
        // Show some error
      }
    });
  }

  changePage(url){
    changePage(url,this.router);
  }

  login(){
    presentLoading(this.loadingController).then(()=>{
      let errorMessage = "";
      if (this.email == ""){
        errorMessage = "You must enter an email";
      }
      else if (this.password == ""){
        errorMessage = "You must enter a password";
      }
  
      if (errorMessage != ""){
        presentToast(errorMessage,"danger",this.toastController);
        this.loadingController.dismiss();
      }
      else{
        this.userAuthService.loginWithEmailPass(this.email,this.password).catch(err=>{
        }).finally(()=>{
          this.loadingController.dismiss();
        })
      }
      
    })
   
  }

}
