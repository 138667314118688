import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActionSheetController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { FrequencyType, PlantAction } from 'node_js/models/tables/plant-action';
import { PlantActionComplete } from 'node_js/models/plant-action-complete';
import { PlantComplete } from 'node_js/models/plant-complete';
import { PlantStat } from 'node_js/models/tables/plant-stat';
import { PlantTemplate } from 'node_js/models/tables/plant-template';
import { PlantTemplateComplete } from 'node_js/models/plant-template-complete';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { PlantActionService } from 'src/app/services/plant-action.service';
import { PlantTemplateService } from 'src/app/services/plant-template.service';
import { PlantService } from 'src/app/services/plant.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { convertDateToUTCString, convertUTCStringToLocalDate, convertUTCStringToUTCDate, presentLoading, presentToast } from '../../../global';
import { ImageProvider } from 'src/app/providers/image/image';
import { Capacitor } from '@capacitor/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { PlantPhoto } from 'node_js/models/tables/plant-photo';

export enum PlantType {
  Greenhouse = "greenhouse",
  Template = "template"
}

@Component({
  selector: 'upsert-plant',
  templateUrl: './upsert-plant.component.html',
  styleUrls: ['./upsert-plant.component.scss'],
})
export class UpsertPlantComponent implements OnInit {

  FrequencyType = FrequencyType;
  PlantType = PlantType;

  //Sent by parent
  currentPlantType = PlantType.Template;

  userComplete:UserComplete;

  public isDesktop: boolean;


  convertUTCStringToUTCDate = convertUTCStringToUTCDate;
  convertUTCStringToLocalDate = convertUTCStringToLocalDate;
  //If we are editing our plant template, this will be sent by the previous page
  plantTemplate:PlantTemplate;
   
  //Default plant for a template
  plantComplete:PlantComplete = {
    plant:{
      id: null,
      name: "Corn",
      difficulty: "Easy",
      estimatedTimeToGrowVal: "1",
      estimatedTimeToGrowFreq: FrequencyType.Months,
    },
    plantActionsComplete: [
      {
        name: "Water",
        nextActionStartDate: null,
        nextActionEndDate: null,
        durationMinutes: null,
        runAutomatically: false,
        active: false,
        frequencyAmount: 1,
        frequencyType: FrequencyType.Days,
        plantId: null,
        plantStatId: null,
        clientId: null
      }
    ],
    plantStats: [
      {
        id: null,
        name: "moisture",
        min: 10,
        max:20,
        value: 15,
        plantId: null
      }
    ],
    plantPhotos: []
  }

  updatingActions = false;
  updatingPlant = false;
   
  greenhouseId = null;

  constructor(private modalController:ModalController,private plantTemplateService:PlantTemplateService, 
    private userAuthService:UserAuthService, private loadingController:LoadingController, 
    private changeDetectorRef:ChangeDetectorRef, private plantService:PlantService, private toastController:ToastController,
    private plantActionService:PlantActionService, private actionSheetController:ActionSheetController,
    private _IMAGE : ImageProvider, private fileUploadService:FileUploadService) { 
     
    let platform = Capacitor.getPlatform()
      // Are we on mobile?
    if(platform == 'ios' || platform == 'android')
    {
       this.isDesktop = false;
    }
    // Or web?
    else
    {
       this.isDesktop = true;
    }
    console.log(platform)
    console.log("is desktop is " + this.isDesktop);
  }

  async ngOnInit() {

    
   
    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
        this.setup();
      }
      else{
        this.userComplete = null;
      }
    })

   
    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
        this.setup();
      }
    }
  }

  setup(){
    if (this.currentPlantType == PlantType.Greenhouse){
      setInterval(()=>{
        if (!this.updatingActions && !this.updatingPlant){
          this.updatingActions = true;
          let actionIds = [];
          for (let i = 0; i < this.plantComplete.plantActionsComplete.length; i++){
            actionIds.push(this.plantComplete.plantActionsComplete[i].id);
          }
          if (actionIds.length > 0){
            this.plantActionService.getPlantActions(actionIds).then((plantActionsComplete:PlantActionComplete[])=>{
              if (this.updatingPlant){
                this.updatingActions = false;
                return;
              }
              console.log("got new actions",plantActionsComplete);
              this.updatingActions = false;
              for (let i = 0; i < this.plantComplete.plantActionsComplete.length; i++){

                let localAction = this.plantComplete.plantActionsComplete[i];
                for (let j = 0; j < plantActionsComplete.length; j++){
                  let serverAction = plantActionsComplete[j];
                  console.log(`if ${localAction.id} == ${serverAction.id}`)
                  if (localAction.id == serverAction.id){
                    localAction.clientId = serverAction.clientId;
                    localAction.active = serverAction.active;
                    break;
                  }
                }
              }
            }).finally(()=>{
              this.updatingActions = false;
            })
          }
          else{
            this.updatingActions = false;
          }
        }
        
      },10000);
    }
  }

  confirm(){
    this.modalController.dismiss(null, 'confirm');
  }

  cancel(){
    this.modalController.dismiss(null, 'cancel');
  }

  addNewStat(){
    let newStat:PlantStat = {
      //Set our default ID to a negative number so it'll never be the same as another record. We can use this number
      //To pair actions to our stat, and then when we insert them into the db we can change the id there.
      id: (this.plantComplete.plantStats.length + 1) * -1,
      name: "",
      min: 0,
      max:0,
      value: 0,
      plantId: null
    };
    this.plantComplete.plantStats.push(newStat);
  }

  addNewAction(){
    let newAction:PlantActionComplete = {
      id: null,
      name: "",
      nextActionStartDate: null,
      nextActionEndDate: null,
      durationMinutes: null,
      runAutomatically: false,
      active: false,
      frequencyAmount: 1,
      frequencyType: FrequencyType.Hours,
      plantId: null,
      plantStatId: null,
      clientId:null
    }
   
    this.plantComplete.plantActionsComplete.push(newAction);
    console.log("added action");
  }

  enabledActionEndDate(plantAction:PlantAction,enabled){
    if (enabled){
      
      let nextLocalDate:Date = new Date(convertUTCStringToLocalDate(plantAction.nextActionStartDate));
      nextLocalDate.setHours(nextLocalDate.getHours() + 5);
      plantAction.nextActionEndDate = convertDateToUTCString(nextLocalDate);
    }
    else{
      plantAction.nextActionEndDate = null;
    }
  }

  convertDateToUTCString(plantAction:PlantAction,prop,owlDate){
    plantAction[prop] = convertDateToUTCString(new Date(owlDate));
  }

  updatePlantActionStatus(plantAction:PlantAction){
    if (this.greenhouseId == null || plantAction.id == null){
      return;
    }
    presentLoading(this.loadingController).then(()=>{
      this.plantActionService.updatePlantActionStatus(this.greenhouseId,plantAction.id,plantAction.active,this.userComplete.user.id).finally(()=>{
        this.loadingController.dismiss();
      })
    })
   
  }

  async updatePlant(){

    if (!this.updatingPlant){
      this.updatingPlant = true;
      presentLoading(this.loadingController).then(async ()=>{
        console.log("Adding template",this.plantComplete);
  
        new Promise<void>((resolve,reject)=>{
          if (this.currentPlantType == PlantType.Template){
  
            //If this is a new plant template, set its default properties
            if (!this.plantTemplate){
              this.plantTemplate = {
                id: null,
                plantId: null,
                global: false,
                userId: this.userComplete.user.id
              }
            }
            
            this.plantTemplateService.createOrUpdatePlantTemplate(this.plantComplete,this.plantTemplate,this.userComplete.user).then((plantTemplateComplete:PlantTemplateComplete)=>{
              this.plantComplete = plantTemplateComplete.plantComplete;
              resolve();
            }).catch(err=>{
              reject(err);
            })
          }
          else{
            this.plantService.createOrUpdatePlantComplete(this.plantComplete,this.userComplete.user).then(()=>{
              resolve();
            }).catch(err=>{
              reject(err);
            })
          }
        }).catch(err=>{
          presentToast(err,"danger",this.toastController);
          this.changeDetectorRef.detectChanges();
          this.loadingController.dismiss();
        }).finally(()=>{
          this.changeDetectorRef.detectChanges();
          this.loadingController.dismiss();
          this.updatingPlant = false;

          if (this.currentPlantType == PlantType.Template){
            this.confirm();
          }
        });
        
        
      })
    }
    
    
  }

  async presentActionSheet() {

    const actionSheet = await this.actionSheetController.create({
      header: "Get Image",
      buttons: [{
        text: "Take Picture",
        role: 'close',
        icon: 'trash',
        handler: () => {
          document.getElementById("input-camera").click();
          
        }
      }, 
       {
        text: "Storage",
        icon: 'close',
        role: 'cancel',
        handler: () => {
          document.getElementById("input-storage").click();

          //this.getPicture("gallery");
          //this.openGallery();
        }
      }]
    });
    await actionSheet.present();
  }

  //Called from html input (desktop)
  public selectImage(event: any)
   {
     
      this._IMAGE
      .selectImage(event)
      .subscribe(async (res) =>
      {
        presentLoading(this.loadingController,"Uploading image...").then(()=>{
          this.getOrientation(event.target.files[0], (rotation=>{
            console.log("File");
            console.log(event.target.files[0]);
            let base64 = "";
            new Promise<void>((resolve,reject)=>{
              if (rotation == 6){
                this.rotate64(res,360).then(function(rotated) {
                  base64 = "data:image/png;base64," + rotated;
                  resolve();
                }).catch(err=>{
                  reject(err);
                });
    
              }
              else{
                base64 = res;
                resolve();
              }
            }).finally(()=>{
              console.log("got img:",base64);
              this.fileUploadService.uploadPlantImage(this.plantComplete.plant.id,base64).finally(()=>{
                this.loadingController.dismiss();
              })
            })
            
          }));
        })

        

      });
   }



   


  getOrientation = (file: File, callback: Function) => {
    var reader = new FileReader();
  
    reader.onload = (event: ProgressEvent) => {
  
      if (! event.target) {
        return;
      }
  
      const file = event.target as FileReader;
      const view = new DataView(file.result as ArrayBuffer);
  
      if (view.getUint16(0, false) != 0xFFD8) {
          return callback(-2);
      }
  
      const length = view.byteLength
      let offset = 2;
  
      while (offset < length)
      {
          if (view.getUint16(offset+2, false) <= 8) return callback(-1);
          let marker = view.getUint16(offset, false);
          offset += 2;
  
          if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) {
              return callback(-1);
            }
  
            let little = view.getUint16(offset += 6, false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            let tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little));
              }
            }
          } else if ((marker & 0xFF00) != 0xFF00) {
              break;
          }
          else {
              offset += view.getUint16(offset, false);
          }
      }
      return callback(-1);
    };
  
    reader.readAsArrayBuffer(file);
  }

  async rotate64(base64data, degrees?, enableURI?) {
    return new Promise(function(resolve, reject) {
      //assume 90 degrees if not provided
      degrees = degrees ? degrees : 90;
    
      var canvas = document.createElement("canvas");
      canvas.setAttribute("id", "hidden-canvas");
      canvas.style.display = "none";
      document.body.appendChild(canvas);
    
      var ctx = canvas.getContext("2d");
  
      var image = new Image();
      //assume png if not provided
      image.src = (base64data.indexOf(",") == -1 ? "data:image/png;base64," : "") + base64data;
      image.onload = function() {
        var w = image.width;
        var h = image.height;
        var rads = degrees * Math.PI/180;
        var c = Math.cos(rads);
        var s = Math.sin(rads);
        if (s < 0) { s = -s; }
        if (c < 0) { c = -c; }
        //use translated width and height for new canvas
        canvas.width = h * s + w * c;
        canvas.height = h * c + w * s;
        //draw the rect in the center of the newly sized canvas
        ctx.translate(canvas.width/2, canvas.height/2);
        ctx.rotate(degrees * Math.PI / 180);
        ctx.drawImage(image, -image.width/2, -image.height/2);
        //assume plain base64 if not provided
        resolve(enableURI ? canvas.toDataURL() : canvas.toDataURL().split(",")[1]);
        document.body.removeChild(canvas);
      };
      image.onerror = function() {
        reject("Unable to rotate data\n" + image.src);
      };
    });
  }

  deletePhoto(plantPhoto:PlantPhoto){
    presentLoading(this.loadingController).finally(()=>{
      this.fileUploadService.deletePlantPhoto(plantPhoto).finally(()=>{
        this.loadingController.dismiss();
      });
    })
    
  }
}
