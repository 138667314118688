import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storage:Storage = null;

  constructor(private storageRef:Storage) { 
    this.validateStorage();
  }

  async validateStorage(){
    if (this.storage == null){
      this.storage = await this.storageRef.create();
    }
  }

  async getStorage(key){
    await this.validateStorage();
    return await this.storage.get(key);
  }

  async setStorage(key,val){
    await this.validateStorage();
    return await this.storage.set(key,val);
  }

  async removeStorage(key){
    await this.validateStorage();
    await this.storage.remove(key);
  }
}
