import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PlantComplete } from 'node_js/models/plant-complete';
import { PlantTemplate } from 'node_js/models/tables/plant-template';
import { User } from 'node_js/models/tables/user.model';
import { connLink, presentToast } from 'src/global';
@Injectable({
  providedIn: 'root'
})
export class PlantService {

  

  constructor(private http:HttpClient, private toastController:ToastController) { 
    
  }

  createOrUpdatePlantComplete(plantComplete:PlantComplete,user:User){
    console.log("create or update plant complete",plantComplete);
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/updatePlantsComplete",{plantsComplete:[plantComplete],user}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
