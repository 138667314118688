import { Injectable } from '@angular/core';
import { User } from 'node_js/models/tables/user.model';
import { validateEmail } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  createNewUser(){
    let user:User = {
      email: "",
      password: "",
      firstName:"",
      lastName:"",
      phoneNumber:"",
      apiToken: ""
    }
    return user;
  }

  validateUserBeforeSignUp(user:User,confirmPassword?){
    let errorMessage = "";
    if (user.firstName == ""){
        errorMessage = "You must enter your first name";
    }
    else if (user.firstName.length > 50){
        errorMessage = "First name must be no more than 50 characters";
    }
    else if (user.lastName == ""){
      errorMessage = "You must enter your last name";
    }
    else if (user.lastName.length > 50){
      errorMessage = "Last name must be no more than 50 characters";
    }
    else if (user.email == ""){
        errorMessage = "You must enter an email"; 
    }
    else if (user.email.length > 64 || !validateEmail(user.email)){
        errorMessage = "Invalid email"
    }
    else if (user.password.length < 8 || user.password.length > 20){
        errorMessage = "Password must be between 8 and 20 characters"
    }
    else if (user.password == "" || (confirmPassword && confirmPassword == "")){
        errorMessage = "You must enter a password";
    }
    else if (confirmPassword && user.password != confirmPassword){
        errorMessage = "Both passwords must match";
    }
   
    else if (user.phoneNumber == ""){
      errorMessage = "You must enter your phone number";
    }
    return errorMessage;
  }
}
