import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GreenhouseUser } from 'node_js/models/tables/greenhouse-user';
import { PermissionType } from '../../../../node_js/models/tables/permission';
import { User } from 'node_js/models/tables/user.model';
import { presentToast } from 'src/global';
import { GreenhouseUserComplete } from 'node_js/models/greenhouse-user-complete';
import { GreenhouseComplete } from 'node_js/models/greenhouse-complete';
import { GreenhouseUserRequest } from 'node_js/models/tables/greenhouse-user-request';
import { GreenhouseUserRequestService } from 'src/app/services/greenhouse-user-request.service';

enum PageState {
  Users = "Users",
  Requests = "Requests"
}

@Component({
  selector: 'app-greenhouse-users',
  templateUrl: './greenhouse-users.component.html',
  styleUrls: ['./greenhouse-users.component.scss'],
})
export class GreenhouseUsersComponent implements OnInit {

  PageState = PageState;
  currentPageState:PageState = PageState.Users;

  greenhouseComplete:GreenhouseComplete;

  PermissionType=PermissionType;
 

  //INPUT
  user:User;
  greenhouseUser:GreenhouseUser;

  selectedUsers = [];

  selectedUserRequests = [];

  passedCheck = false;

  constructor(private toastController:ToastController, private modalController:ModalController, private greenhouseUserRequestService:GreenhouseUserRequestService) { }

  ngOnInit() {
    console.log("got greenhouse users",this.greenhouseComplete);

    //Make sure the modal is opened all the way before we force close it do to lack of permissions
    setTimeout(()=>{
      
      if (!this.greenhouseUser || this.greenhouseUser.permissionId != PermissionType.Owner){
        presentToast("You do not have permission to view this page!","danger",this.toastController);
        this.cancel();
        return;
      }
      this.passedCheck = true;
    },1)
    
  }

  setPageState(pageState:PageState){
    this.currentPageState = pageState;
  }

  selectedUser(event,index, usersList){
    console.log(event.target.checked);
    if (event.target.checked){
      usersList.push(index);
    }
    else{
      for (let i = 0; i < usersList.length; i++){
        if (usersList[i] == index){
          usersList.splice(i,1);
        }
      }
    }
  }

  viewLogs(){

  }



  cancel(){
    console.log("modal controller",this.modalController);
    this.modalController.dismiss();
  }

  removeUsers(){
    if (this.selectedUsers.length == 0){
      presentToast("Please select users to remove","danger",this.toastController);
    }
    else{
      console.log("removing users:",this.selectedUsers);
    }
  }

  acceptUserRequests(){
    let userIds = this.getSelectedUserIds();
    if (userIds.length > 0){
      this.greenhouseUserRequestService.acceptUsers(this.greenhouseComplete.greenhouse.id,userIds,this.user);
    }
  }

  rejectUserRequests(){
    let userIds = this.getSelectedUserIds();
    if (userIds.length > 0){
      this.greenhouseUserRequestService.rejectUsers(this.greenhouseComplete.greenhouse.id,userIds,this.user);
    }
  }

  getSelectedUserIds(){
    let userIds = [];
    for (let i = 0; i < this.selectedUserRequests.length; i++){
      userIds.push(this.greenhouseComplete.greenhouseUserRequestsComplete[this.selectedUserRequests[i]].greenhouseUserRequest.userId)
    }
    return userIds;
  }
}
