import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { connLink } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class PlantActionService {


  constructor(private http:HttpClient, private toastController:ToastController) { 
    
  }

  updatePlantActionStatus(greenhouseId,actionId,active,userId){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/setActionState",{greenhouseId,actionId,active,userId}).subscribe(
  
        (response) => {     
          resolve(); 
          console.log("response:",response);  
        },
        (errorObj) => {                  
          //presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    })
  }

  getPlantActions(actionIds){
    return new Promise((resolve,reject)=>{
      this.http.post(connLink + "/getPlantActionsComplete",{actionIds}).subscribe(
  
        (response) => {      
          console.log(response);  
          resolve(response["data"]["plantActionsComplete"]);                 
        },
        (errorObj) => {                  
          //presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
    
  }

  
}
