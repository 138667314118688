import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { changePage, connLink, presentLoading, presentToast } from 'src/global';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {

  email = "";
  submittedPasswordChangeRequest = false;

  constructor(private router:Router,private http:HttpClient, private toastController:ToastController,private loadingController:LoadingController) { }

  ngOnInit() {
  }
  changePage(url){
    changePage(url,this.router);
  }

  forgotPassword(){
    this.submittedPasswordChangeRequest = false;

    if (this.email == ""){
      presentToast("You must enter an email","danger",this.toastController);
      return;
    }

    presentLoading(this.loadingController).then(()=>{
      return new Promise<void>((resolve,reject)=>{
        this.http.post(connLink + "/createPasswordChangeRequest",{email:this.email}).subscribe(
        
          (response) => {   
            this.submittedPasswordChangeRequest = true;
            console.log(response);  
            resolve();                 
          },
          (errorObj) => {                  
            presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
            reject();
        })
      }).finally(()=>{
        this.loadingController.dismiss();
      });
    })
  
  }
}
