import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ErrorCode } from 'node_js/models/failed-response.model';
import { Greenhouse } from 'node_js/models/tables/greenhouse';
import { GreenhouseUser } from 'node_js/models/tables/greenhouse-user';
import { UserComplete } from 'node_js/models/user-complete.model';
import { User } from 'node_js/models/tables/user.model';
import { GreenhouseUserService } from 'src/app/services/greenhouse-user.service';
import { GreenhouseService } from 'src/app/services/greenhouse.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { presentToast } from 'src/global';

enum NewGreenhouseType{
  New = "New",
  Join = "Join"
}


@Component({
  selector: 'app-add-greenhouse',
  templateUrl: './add-greenhouse.component.html',
  styleUrls: ['./add-greenhouse.component.scss'],
})
export class AddGreenhouseComponent implements OnInit {

  NewGreenhouseType = NewGreenhouseType;

  newGreenhouseType:NewGreenhouseType = this.NewGreenhouseType.New;


  joinId = "";
  joinPassword = "";

  greenhouse:Greenhouse = {
    password: "",
    name: "",
    requireJoinApproval: true
  };

  userComplete:UserComplete;

  editing = false;
  setNewPassword = false;
  newPassword = "";

  constructor(private toastController:ToastController, private modalController:ModalController, 
    private greenhouseService:GreenhouseService, private userAuthService:UserAuthService, private greenhouseUserService:GreenhouseUserService) { }

  ngOnInit() {

    if (this.editing){
      this.newGreenhouseType = NewGreenhouseType.New;
    }

    console.log(this.greenhouse);

    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
      }
    }
  }

  cancel() {
    this.modalController.dismiss(null,"cancel");
  }

  finish(){
    let errorMessage = "";
    if (this.newGreenhouseType == NewGreenhouseType.Join){

      if (this.joinId == ""){
        errorMessage = "You must enter a greenhouse ID";
      }
      else{
        this.greenhouseUserService.joinGreenhouse(this.joinId,this.joinPassword,this.userComplete.user).then(message=>{
          presentToast(message,"success",this.toastController);
          this.modalController.dismiss(null,"confirm");
        }).catch(errorObj=>{
          console.log(errorObj);
          if (errorObj.errorCode == ErrorCode.DataNotFound){
            presentToast("A greenhouse under this ID does not exist!","danger",this.toastController);
          }
          else if (errorObj.errorCode == ErrorCode.InvalidPassword){
            presentToast("The password is incorrect!","danger",this.toastController);
          }
          else if (errorObj.errorCode == ErrorCode.PreventingDuplicateData){
            presentToast(errorObj.errorMessage,"danger",this.toastController);
          }
          else{
            presentToast("An unexpected error has occured!","danger",this.toastController);
          }
        });
      }
    }
    else if (this.newGreenhouseType == NewGreenhouseType.New){
      if (this.greenhouse.name == ""){
        errorMessage = "You must name your greenhouse";
      }
      else {
       
        if (this.editing){
          let newPassword = this.setNewPassword ? this.newPassword : null;
          this.greenhouseService.updateGreenhouse(this.greenhouse,this.userComplete.user,newPassword).then(()=>{
            this.modalController.dismiss(null,"confirm");
          }).catch(err=>{
            console.log("error creating greenhouse",err);
          })
        }
        else{
          this.greenhouseService.createGreenhouse(this.greenhouse,this.userComplete.user).then(()=>{
            this.modalController.dismiss(null,"confirm");
          }).catch(err=>{
            console.log("error creating greenhouse",err);
          })
        }
        
      }
    }

    if (errorMessage != ""){
      presentToast(errorMessage,"danger",this.toastController);
    }
    
  }

}
