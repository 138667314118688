import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

export interface NavParam {
  code: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class NavParamsService {
  public navParamsSetObservable: BehaviorSubject<any>;

  constructor() { 
    this.navParamsSetObservable = new BehaviorSubject(false);
  }


  async setParams(navParam:NavParam, storageService:StorageService){
    await storageService.setStorage('navParams',navParam);
    this.navParamsSetObservable.next(navParam);
  }

  async getParams(storageService:StorageService){
    let data = await storageService.getStorage("navParams");
    if (data){
      await storageService.removeStorage("navParams");
    }
    return data;
  }


}
