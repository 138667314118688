import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { changePage } from 'src/global';
import { NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

import { User } from 'node_js/models/tables/user.model';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { UserComplete } from 'node_js/models/user-complete.model';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  providers: [NavParams,Storage]

})
export class HomePage implements OnInit {

  userComplete:UserComplete;

  constructor(private userAuthService:UserAuthService, private router:Router) {

  }

  async ngOnInit(): Promise<void> {

    
     

    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(userComplete=>{
      console.log("obs trigger",userComplete);
      //False means the observable has just loaded
      if (userComplete != false && userComplete != null){
        this.userComplete = userComplete;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
      }
    }
  }

  changePage(url){
    changePage(url,this.router);
  }

x}
