import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { PlantTemplateComplete } from '../../../../node_js/models/plant-template-complete';
import { User } from '../../../../node_js/models/tables/user.model';
import { UpsertPlantComponent, PlantType } from 'src/app/components/upsert-plant/upsert-plant.component';
import { NavParam, NavParamsService } from 'src/app/services/nav-params.service';
import { PlantTemplateService } from 'src/app/services/plant-template.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { UserComplete } from 'node_js/models/user-complete.model';
import { presentLoading } from 'src/global';

@Component({
  selector: 'app-personal-plant-templates',
  templateUrl: './personal-plant-templates.page.html',
  styleUrls: ['./personal-plant-templates.page.scss'],
})
export class PersonalPlantTemplatesPage implements OnInit {


  plantTemplatesComplete:PlantTemplateComplete[] = [];
  userComplete:UserComplete;

  constructor(private modalController:ModalController,private plantTemplateService:PlantTemplateService, 
    private userAuthService:UserAuthService, private loadingController:LoadingController, private storageService:StorageService) { }

  ngOnInit() {
    //Subscribe to see if we login/logout
    this.userAuthService.userObservable.subscribe(user=>{
      console.log("obs trigger",user);
      //False means the observable has just loaded
      if (user != false && user != null){
        this.userComplete = user;
        this.setup();
      }
      else{
        this.userComplete = null;
      }
    })

    //If this wasn't the first page loaded, we may already be logged in/out and the observable won't fire.
    //Check to see if we already have this info
    if (this.userAuthService.initalLoginCheck){
      if (this.userAuthService.userComplete){
        this.userComplete = this.userAuthService.userComplete;
        this.setup();
      }
    }
    
  }

  setup(){
    return new Promise<void>((resolve,reject)=>{
      this.plantTemplateService.getPersonalPlantTemplatesByUserId(this.userComplete.user).then((plantTemplatesComplete:PlantTemplateComplete[])=>{
        console.log("got plants",plantTemplatesComplete)
        this.plantTemplatesComplete = plantTemplatesComplete;
      }).finally(()=>{
        resolve();
      })
    })
    
  }

  selectedPlant(plantTemplateComplete:PlantTemplateComplete){
    console.log("sending plant template complete:",plantTemplateComplete);
    this.openUpsertPlantModal({plantTemplate:plantTemplateComplete.plantTemplate,plantComplete:plantTemplateComplete.plantComplete,currentPlantType:PlantType.Template});
  }

  async openUpsertPlantModal(props?){
    const modal = await this.modalController.create({
      component: UpsertPlantComponent,
      cssClass: 'custom-modal-css',
      componentProps: props
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      console.log("confirm?");
      presentLoading(this.loadingController).then(()=>{
        this.setup().finally(()=>{
          this.loadingController.dismiss();
        })
      })
    }
    else if (role === "cancel"){
      console.log("cancel");
      
    }
  }

}
