import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PlantPhoto } from 'node_js/models/tables/plant-photo';
import { connLink, presentToast } from 'src/global';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {


  constructor(private http:HttpClient, private toastController:ToastController, private userAuthService:UserAuthService) {

   
  }

  deletePlantPhoto(plantPhoto:PlantPhoto){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/deletePlantPhoto",{plantPhoto}).subscribe(
  
        (response) => {      
          console.log("response",response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }

  uploadPlantImage(plantId,base64){
    return new Promise<void>((resolve,reject)=>{
      let fileName = Date.now();
      console.log("sending plant id ",plantId);
      this.http.post(connLink + "/uploadPlantPhoto",{fileName,base64,plantId}).subscribe(
  
        (response) => {      
          console.log("response",response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
    
  }

  uploadImageBase64(folderPath,fileName,base64){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/uploadImageBase64",{folderPath,fileName,base64}).subscribe(
  
        (response) => {      
          console.log("response",response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    });
  }
}
