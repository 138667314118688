import { Router } from "@angular/router";

//export const connLink = "http://localhost:3000";
export const connLink = "https://greenhouse-api.brilliantlabs.ca";

console.log("conn link: " + connLink);

export function changePage(url, router:Router){

  router.navigateByUrl(url);
}

//Take a date and convert it to UTC string for mysql
export function convertDateToUTCString(date:Date){
  if (date == null){
    date = new Date();
  }
  //Created formatted utc date that can be stored in mysql
  return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) +"-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
}

//Converts our utcString (mysql) into a date object
export function convertUTCStringToUTCDate(utcString){
  utcString += " UTC";
  //console.log("utc date",new Date(utcString).toJSON())
  return new Date(utcString).toJSON();
}

//Convert utc string to local date 
//NOTE: IF DATE SENT IN IS NOT IN UTC, YOU WILL GET AN INCORRECT LOCAL DATE
export function convertUTCStringToLocalDate(utcString){
  utcString += " UTC";
  var localDate = new Date(utcString);
  localDate = new Date(localDate.toString());
  return localDate;
}



export async function presentToast(message, color, toastController, length?) {
    let toastLength = length?length:5000;
    const toast = await toastController.create({
      message: message,
      duration: toastLength,
      color: color,
    });
    toast.present();
}

export function presentLoading(loadingController, message?) {
    if (!message){
      message = "Please wait...";
    }
    return new Promise<void>(async (resolve,reject)=>{
      const loading = await loadingController.create({
        cssClass: 'my-custom-class',
        message: message
      });
      
      resolve(await loading.present());
    })
   
  
    
}



export async function presentAlertConfirm(alertController, header, message) {

  return new Promise(async (resolve, reject) => {
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
            reject();
            
          }
        }, {
          text: 'Yes',
          handler: () => {
            alert.dismiss();
            resolve(true);
            
          }
        }
      ]
    });
  
    await alert.present();
  })
  
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};