import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { UserAuthService } from './user-auth.service';
import { Device } from '@capacitor/device';
import { User } from 'node_js/models/tables/user.model';
import { UserDevice } from 'node_js/models/tables/user-device.model';
import { connLink, presentToast } from 'src/global';
import { UserPushNotification } from 'node_js/models/tables/user-push-notification';



@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {


  constructor(private http:HttpClient, private toastController:ToastController, private userAuthService:UserAuthService) {

  }


  updateUserPushNotificationSettings(user:User,userPushNotifications:UserPushNotification[]){
    return new Promise<void>((resolve,reject)=>{
      this.http.post(connLink + "/updateUserPushNotificationSettings",{user,userPushNotifications}).subscribe(
        (response) => {      
          this.userAuthService.userComplete.userPushNotifications = response["data"]["userPushNotifications"];
          console.log(response);  
          resolve();                 
        },
        (errorObj) => {                  
          presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
          reject();
      })
    })
  }

  refreshPushNotificationToken(user:User,userDevice:UserDevice){

    return new Promise<void>((resolve,reject)=>{
      let isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
      isPushNotificationsAvailable = true;
      console.log("testing push");
      if (isPushNotificationsAvailable) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
              PushNotifications.addListener('registration',(token: Token) => {
                this.http.post(connLink + "/upsertFCMToken",{userDeviceId:userDevice.id,user,fcmToken:token.value}).subscribe(
                  (response) => {      
                    console.log(response);  
                    alert(`Push registration success, device:${userDevice.deviceId} , token: ${token.value}`);
                    resolve();                 
                  },
                  (errorObj) => {                  
                    presentToast(errorObj.error.userErrorMessage,"danger",this.toastController);
                    reject();
                })
              });
    
              PushNotifications.addListener('registrationError',(error: any) => {
                alert('Error on registration: ' + JSON.stringify(error));
              });
    
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register().then();
  
            
          } else {
            // Show some error
          }
        });
      }
    });
  }

  registerPushNotification(){
    
  }
   
  
      /*
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          this.email = token.value;
          alert('Push registration success, token: ' + token.value);
        }
      );
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          alert('Push received: ' + JSON.stringify(notification));
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          alert('Push action performed: ' + JSON.stringify(notification));
        }
      );    
      */
    //}
}
